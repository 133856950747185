
import { createStore } from 'framework7/lite';

const store = createStore({
  state: {
    line: {},
    token: {},
  },
  getters: {
    line({ state }) {
      return state.line;
    },
    token({ state }) {
      return state.token;
    }
  },
  actions: {
    setLine({ state }, payload) {
      state.line = payload;
    },
    setToken({ state }, payload) {
      state.token = payload;
    },
  },
})
export default store;
