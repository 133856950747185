import Axios from "Axios";
import BaseAPIURL from "../config/baseURL";
import store from "../js/store";

/**
 * Service to call HTTP request via Axios
 */

const instance = Axios.create({
  baseURL: BaseAPIURL,
  // timeout: 1000,
  // headers: {'Authorization': `Bearer ${store.getters.token.value}`}
});

const ApiService = {
  setHeader() {
    instance.defaults.headers.common["Authorization"] = `Bearer ${store.getters.token.value}`;
  },

  auth(resource, params) {
    delete instance.defaults.headers.Authorization; // เอาออกเพราะไม่สามารถ login ได้ถ้าหากมี token
    return instance.post(`${resource}`, params);
  },

  query(resource, params) {
    return instance.get(`${resource}?${params}`).catch(error => {
      // console.log(error);
      return error.response;
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    slug = slug ? `/${slug}` : "";
    return instance.get(`${resource}${slug}`).catch(error => {
      // console.log(error);
      if (error.response && error.response.status === 401) {
        return Promise.reject(error.response);
      } else {
        return error.response;
      }
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    const payload = {
      ...params,
      createdByJSON: store.state && store.state.auth && store.state.auth.user ? store.state.auth.user : {},
      createdByName: store.state && store.state.auth && store.state.auth.user && store.state.auth.user.username && store.state.auth.user.username !== "" ? store.state.auth.user.username : "",
      createdById: store.state && store.state.auth && store.state.auth.user && store.state.auth.user.id && store.state.auth.user.id !== "" ? store.state.auth.user.id : "",
      createdDateTime: new Date()
    };
    return instance.post(`${resource}`, payload).catch(error => {
      // console.log(error.response)
      return error.response;
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    const payload = {
      ...params,
      updatedByJSON: store.state && store.state.auth && store.state.auth.user ? store.state.auth.user : {},
      updatedByName: store.state && store.state.auth && store.state.auth.user && store.state.auth.user.username && store.state.auth.user.username !== "" ? store.state.auth.user.username : "",
      updatedById: store.state && store.state.auth && store.state.auth.user && store.state.auth.user.id && store.state.auth.user.id !== "" ? store.state.auth.user.id : "",
      updatedDateTime: new Date()
    };
    return instance.put(`${resource}/${slug}`, payload).catch(error => {
      // console.log(error.response)
      return error.response;
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    const payload = {
      ...params,
      updatedByJSON: store.state && store.state.auth && store.state.auth.user ? store.state.auth.user : {},
      updatedByName: store.state && store.state.auth && store.state.auth.user && store.state.auth.user.username && store.state.auth.user.username !== "" ? store.state.auth.user.username : "",
      updatedById: store.state && store.state.auth && store.state.auth.user && store.state.auth.user.id && store.state.auth.user.id !== "" ? store.state.auth.user.id : "",
      updatedDateTime: new Date()
    };
    return instance.put(`${resource}`, payload).catch(error => {
      // console.log(error);
      return error.response;
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return instance.delete(resource).catch(error => {
      // console.log(error);
      return error.response;
    });
  },

  upload(fileData) {
    const formData = new FormData();

    Array.from(fileData).forEach(file => {
      formData.append("files", file);
    });

    return instance.post(`upload`, formData).catch(error => {
      // console.log(error);
      return error.response;
    });
  },

  saveLog(payload) {
    return instance.post(`system-logs`, payload).catch(error => {
      // console.log(error.response)
      return error.response;
    });
  },
};

export default ApiService;
