
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import NotFoundPage from '../pages/404.vue';
import DetailPage from '../pages/detail.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/detail/:id',
    component: DetailPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
