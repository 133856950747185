<template>
  <f7-page name="detail">
    <f7-navbar :sliding="false" back-link="Back"></f7-navbar>
    <!-- Timeline -->
    <div class="timeline">
      <!-- Timeline item -->
      <div
        class="timeline-item"
        v-for="(item, index) in timelineList"
        :key="index"
      >
        <div class="timeline-item-date" style="width: 80px;">
          {{ moment(item.created_at).locale("th").fromNow() }}
        </div>
        <div
          class="timeline-item-divider"
          :style="`background-color: ${
            item.status === 'new'
              ? 'blue'
              : item.status === 'completed'
              ? 'green'
              : item.status === 'rejected'
              ? 'red'
              : ''
          }`"
        ></div>
        <div class="timeline-item-content">
          <div class="timeline-item-inner">
            <div class="timeline-item-time">
              เวลา {{ moment(item.created_at).locale("th").format("LT") }}
            </div>
            <div class="timeline-item-title">{{ item.title }}</div>

            <div class="timeline-item-text" style="margin-top: 5%">{{ item.message }}</div>
            <div class="timeline-item-text" style="margin-top: 5%">
              <span class="badge color-blue" v-if="item.status === 'new'">รายการใหม่</span>
              <span
                class="badge color-green"
                v-if="item.status === 'completed'"
                >ผ่านการตรวจสอบ</span
              >
              <span class="badge color-red" v-if="item.status === 'rejected'">ตรวจสอบไม่ผ่าน</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-title">รายละเอียดการโอนเงิน</div>
    <div class="card card-outline">
      <div class="card-header">Username : {{ data.customerRefUser }}</div>
      <div class="card-content card-content-padding">
        <p>จำนวนเงิน : {{ data.transferAmount }} บาท</p>
        <p>
          เวลาที่โอน :
          {{ data.transferDateTime }}
        </p>
        
        <div class="data-after">
          สถานะ :
          <span class="badge color-blue" v-if="data.status === 'new'">รายการใหม่</span>
          <span class="badge color-green" v-if="data.status === 'completed'">ผ่านการตรวจสอบ</span>
          <span class="badge color-red" v-if="data.status === 'rejected'">ตรวจสอบไม่ผ่าน</span>
        </div>
      </div>
      <div class="card-footer">
        <h3>สลิป</h3>
        <div class="row" style="justify-content: center">
          <img
            :src="`${BaseAPIURL}${data.fileUploadThumbnail}`"
            style="width: 100%"
            @click="$refs.standalone.open()"
            v-if="data.fileUploadThumbnail && data.fileUploadThumbnail !== ''"
          />
        </div>
        <f7-photo-browser
          :photos="[`${BaseAPIURL}${data.fileUpload}`]"
          ref="standalone"
          :routable-modals="false"
        ></f7-photo-browser>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { f7, f7ready } from "framework7-vue";
import ApiService from "../utils/api.service";
import moment from "moment";
import BaseAPIURL from "../config/baseURL";

export default {
  data() {
    return {
      data: {},
      timelineList: [],
      moment,
      BaseAPIURL,
    };
  },
  props: {
    f7route: Object,
  },
  created() {
    // console.log('id',this.f7route.params.id);
    this.fetchData(this.f7route.params.id);
  },
  methods: {
    fetchData(id) {
      f7.dialog.preloader();
      ApiService.get("transfers", id)
        .then((res) => {
          console.log("res", res);
          if (res && res.status === 200) {
            this.data = {
              status: res.data && res.data.status ? res.data.status : "",
              transferAmount:
                res.data && res.data.transferAmount
                  ? res.data.transferAmount.toLocaleString()
                  : "",
              transferDateTime:
                res.data && res.data.transferDateTime
                  ? moment(res.data.transferDateTime).locale("th").format("Do MMMM YYYY HH:mm น.")
                  : "",
              customerRefUser:
                res.data && res.data.customerRefUser
                  ? res.data.customerRefUser
                  : "",
              fileUpload:
                res.data &&
                res.data.fileUpload &&
                res.data.fileUpload.length > 0 &&
                res.data.fileUpload[0].url &&
                res.data.fileUpload[0].url !== ""
                  ? res.data.fileUpload[0].url
                  : "",
              fileUploadThumbnail:
                res.data &&
                res.data.fileUpload &&
                res.data.fileUpload.length > 0 &&
                res.data.fileUpload[0].formats &&
                res.data.fileUpload[0].formats.thumbnail &&
                res.data.fileUpload[0].formats.thumbnail.url &&
                res.data.fileUpload[0].formats.thumbnail.url !== ""
                  ? res.data.fileUpload[0].formats.thumbnail.url
                  : res.data &&
                    res.data.fileUpload &&
                    res.data.fileUpload.length > 0 &&
                    res.data.fileUpload[0].url &&
                    res.data.fileUpload[0].url !== ""
                  ? res.data.fileUpload[0].url
                  : "",
              ref: res.data && res.data.ref ? res.data.ref : "",
            };
            this.fetchDataTimeline(
              res.data && res.data.ref ? res.data.ref : ""
            );
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          f7.dialog.close();
        });
    },
    fetchDataTimeline(id) {
      f7.dialog.preloader();
      ApiService.query(
        "/transfer-logs",
        `clientShow=true&transferRef=${id}&_sort=created_at:DESC`
      )
        .then((res) => {
          console.log("res fetch", res);
          if (res.status === 200 && res.data) {
            this.timelineList = res.data;
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          f7.dialog.close();
        });
    },
  },
};
</script>

<style scoped>
.card-footer {
  display: block !important;
}
</style>
