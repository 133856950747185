<template>
  <f7-app v-bind="f7params" >
  <!-- Your main view, should have "view-main" class -->
    <f7-view main class="view-main" url="/"></f7-view>
  </f7-app>
</template>
<script>
  import { ref, onMounted } from 'vue';
  import { f7, f7ready } from 'framework7-vue';
  import routes from '../js/routes.js';
  import store from '../js/store';
  import configLine from '../config/line';
  import ApiService from "../utils/api.service";
  import settings from '../config/settings';

  export default {
    beforeCreate(){

      if(settings.useLineLiff){
        const liff = window.liff
      
        liff.init({
            liffId: configLine.liffId // Use own liffId
          })
          .then((e) => {
            // Start to use liff's api
            console.log("LINELIFF", e)
            if (liff.isLoggedIn()) {
              // ขั้นตอน 4.4 รออยู่
            } else {
              liff.login();
            }
            // f7.dialog.alert('TTEssss: ' + window.liff.getOS())
            // liff.closeWindow();
            
          })
          .catch((err) => {
            // Error happens during initialization
            console.log(err.code, err.message);
          });
      }
      

      
       ApiService.auth("auth/local", {identifier: "app", password: "app@1234"}).then(res => {
         
        if(res.status === 200){
          store.dispatch('setToken', res.data.jwt)
          ApiService.setHeader()
        }
       })

    },
    mounted(){
        if(settings.useLineLiff){
              window.liff.ready.then(() => {
                // do something you want when liff.init finishes
                window.liff.getProfile().then((res) => store.dispatch('setLine', res))
              })
        }
      
    },
    setup() {

      // Framework7 Parameters
      const f7params = {
        name: 'JokerMobile', // App name
        theme: 'ios', // Automatic theme detection



        // App store
        store: store,
        // App routes
        routes: routes,
        // Register service worker
        serviceWorker: {
          path: '/service-worker.js',
        },
      };
     
      onMounted(() => {
        f7ready(() => {
          
          // store.dispatch('setToken', res)
          // Call F7 APIs here
        });
      });

      return {
        f7params,
      }
    }
  }
</script>